<template>
  <section class="content">
    <h2 class="content__title text-title">More On {{ tagName }}</h2>
    <div class="content__items">
      <div
        class="content__item"
        v-for="(item, index) in searchTagResult"
        :key="`item-${index}`"
        @click="$router.push(`/media${item.url}`)"
      >
        <img class="content__item-image" :src="item.search_image" />
        <div class="content__item-type text-sub-title">{{ item.type }}</div>
        <h3 class="content__item-title text-link">
          {{ item.title }}
        </h3>
        <p class="content__item-data text-sub-title">{{ item.date_published }}</p>
      </div>
    </div>
    <div v-if="showLoadMoreButton && hasMorePages" class="content__footer">
      <BaseButton
        class="content__button button--xs button--white"
        text="LOAD MORE"
        @click="handleLoadMoreClick"
      />
    </div>
  </section>
</template>

<script>
import BaseButton from "@/components/buttons/BaseButton.vue";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  components: { BaseButton },
  data() {
    return {
      tag_id: "",
      page: 1,
      showLoadMoreButton: true,
      loading: false,
    };
  },
  head: {
    title: function () {
      return {
        inner: "Tag Conten browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: "Description Tag Conten browngrotta arts",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Tag Conten browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Tag Conten browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Tag Conten browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: "Description Tag Conten browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Tag Conten browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: "Description Tag Conten browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Tag Conten browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  computed: {
    ...mapGetters("publicapi/search", {
      searchTagResult: "getSearchTagResult",
      tagName: "getTagName",
      hasMorePages: "getHasMorePages",
    }),
  },
  async created() {
    this.setBreadcrumbs([]);
    this.tag_id = this.$route.query?.tag_id;
    await this.apiSearchTag({ tag_id: this.tag_id, page: this.page });
  },
  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    ...mapActions("publicapi/search", ["apiSearchTag"]),
    ...mapMutations("publicapi/search", ["setSearchTagResult"]),
    async loadMore() {
      if (this.loading || !this.hasMorePages) return;
      this.loading = true;

      this.page++;
      await this.apiSearchTag({ tag_id: this.tag_id, page: this.page });
      this.loading = false;
    },
    handleLoadMoreClick() {
      this.showLoadMoreButton = false;
      this.loadMore();
      this.enableInfiniteScroll();
    },

    enableInfiniteScroll() {
      window.addEventListener("scroll", this.handleScroll);
    },

    handleScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      const bottomPosition = document.documentElement.scrollHeight;

      if (scrollPosition >= bottomPosition - 100) {
        this.loadMore();
      }
    },
  },
  mounted() {
    if (!this.showLoadMoreButton) {
      this.enableInfiniteScroll();
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 17rem 10.5rem 23rem;
  @media screen and (max-width: $sm) {
    padding: 17rem 5rem 23rem;
  }
  @media screen and (max-width: $xs) {
    padding: 17rem 25px 23rem;
  }
  &__title {
    max-width: 80rem;
    margin: 0 auto 11.5rem;
  }
  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 3rem;
    grid-row-gap: 7rem;
    @media screen and (max-width: $sm) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: $xs) {
      grid-template-columns: 1fr;
    }
  }
  &__item {
    cursor: pointer;

    &-image {
      width: 100%;
      height: calc((100vw - (10.5rem * 2)) / 3);
      object-fit: cover;
      @media screen and (max-width: $sm) {
        height: calc((100vw - (5rem * 2)) / 2);
      }
      @media screen and (max-width: $xs) {
        height: calc(100vw - (25px * 2));
      }
    }
    &-type {
      display: flex;
      justify-content: center;
      align-content: center;
      text-transform: uppercase;
      border: 1px solid $white;
      border-radius: 5px;
      width: max-content;
      min-width: 9.7rem;
      margin-top: 2.6rem;
      font-size: 1.4rem;
      line-height: 1.8;
      padding: 0.1rem 1rem;
    }
    &-title {
      font-size: 2.4rem;
      line-height: 1.3;
      margin-top: 1rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &-data {
      margin-top: 1.2rem;
      text-transform: uppercase;
      font-size: 1.4rem;
      line-height: 1.8;
      @media screen and (max-width: $xs) {
        margin-top: 1rem;
      }
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7rem 25px 0 25px;
  }
  &__button {
    margin-top: 7rem;
    min-width: 180px;
  }
}
</style>

